<template>
  <div class="edit-file">
    <!--<div class="alert alert-success alert-dismissible fade m-0 p-1" role="alert" id ="alert-message">
      <strong>{{ alertContent }}</strong>
    </div>-->
    <CForm ref="updateFile"
       v-on:submit.prevent="updateFile"
       novalidate
       class="edit-file-form"
    >
      <!--<CAlert :show.sync="formData.dismissCountDown"
              closeButton
              fade
              id="edit-success" show color="success" style="display: none">You have successfully editd a new Repository!</CAlert>-->

      <!--  HEADER    -->
      <CreateHeader
        :loading="loading"
        :title="headerTitle"
        :descriptions="descriptions"
      />
      <!--   CONTENT    -->
      <div class="form-content content">
        <!--   REPOSITORY ID      -->
        <ClFormRow
          :label="texts.editFilePage.repoIdLabel"
          :toolTipText="descriptions.repositoryID"
        >
          <template #content>
            <CInput
              placeholder
              v-model="formData.repositoryId"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--     ID   -->
        <ClFormRow
          :label="texts.editFilePage.idLabel"
          :toolTipText="descriptions.id"
        >
          <template #content>
            <CInput
              placeholder
              v-model="formData.id"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--    CREATED    -->
        <ClFormRow
          :label="texts.editFilePage.createdLabel"
          :toolTipText="descriptions.created"
        >
          <template #content>
            <CInput
              :value="dateFormate(formData.created)"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--    PATH    -->
        <ClFormRow
          :label="texts.editFilePage.pathLabel"
          :toolTipText="descriptions.path"
        >
          <template #content>
            <CInput
              placeholder
              v-model="formData.path"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--    NAME    -->
        <ClFormRow
          :label="texts.editFilePage.nameLabel"
          :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
              :placehoder="texts.editFilePage.namePlaceholder"
              v-model="formData.name"
              add-input-classes="col-sm-12"
              required
              invalidFeedback="Required"
              :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
        <!--    TYPE    -->
        <ClFormRow
          v-if="!isFolder"
          :label="texts.editFilePage.typeLabel"
          :toolTipText="descriptions.type"
        >
          <template #content>
            <CInput
              :placeholder="texts.editFilePage.typePlaceholder"
              v-model="formData.mimeType"
              add-input-classes="col-sm-12"
            />
          </template>
        </ClFormRow>

        <!--   TAGS    -->
        <ClFormRow
          :label="texts.editFilePage.tagsLabel"
          :toolTipText="descriptions.tags"
        >
          <template #content>
            <multiselect
              v-model="formData.tags"
              :options="options"
              :multiple="true"
              :preserve-search="true"
              :taggable="true"
              @tag="addTags"
              :selectLabel="selectLabel"
              :tagPlaceholder="tagPlaceholder"
              :deselectLabel="removeLabel"
              class="custom-multi-select zq--multiselect zq--multiselect__green"
            />
            <img
              src="../../../assets/icons/search.svg"
              width="16"
              height="16"
              alt="search"
              class="zq--multiselect-search-icon"
            >
          </template>
        </ClFormRow>
        <!--     METADATA     -->
        <ClFormRow
          :label="texts.editFilePage.metadataLabel"
          tooltipPosition="label"
          :toolTipText="descriptions.metadata"
          xl-label="12"
          md-content="12"
          xl-content="12"
        >
          <template #content>
            <CMetaInputs
              v-if="showCMetaInputs"
              v-on:change="addMeta"
              :fields="formData.metadata"
            />
          </template>
        </ClFormRow>
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import {cloneDeep} from "lodash";
import CMetaInputs from '@/components/forms/CMetaInputs';
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";
import CreateHeader from "@/shared/components/CreateHeader";
import {formConfig} from "@/config";
import {webAssets} from "@/config/descriptions/webAssets"
import {dateFormate} from '@/utils/dateFormate';
import {webAssetsTexts} from "@/config/pageTexts/webAssets.json";

export default {
  name: 'UpdateFile',
  components: {
    CMetaInputs,
    ClFormRow,
    CreateHeader,
  },
  data() {
    return {
      descriptions: {
        ...webAssets.file.update
      },
      texts: {
        ...webAssetsTexts
      },
      selectLabel: formConfig.selectLabel,
      tagPlaceholder: formConfig.tagPlaceholder,
      removeLabel: formConfig.removeLabel,
      formData: {
        id: '',
        repositoryId: '',
        fileName: '',
        path: '',
        tags: [],
        metadata: [],
        type: '',
        repoName: '',
      },
      editorOption: {
        theme: 'snow',
      },
      options: ['tag1', 'tag2', 'tag3'],
      idValidate: null,
      nameValidate: null,
      query: {},
      showCMetaInputs: false,
      isFolder: false,
      sortBy: [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ],
      page: 1,
    };
  },
  created() {
    this.initialize();
  },
  computed: {
    ...mapGetters('files', ['files', 'success', 'message', 'file', 'loading']),
    headerTitle() {
      if (this.isFolder) {
        return `UpdateFolder - ${this.formData.fileName}`;
      } else {
        return `UpdateFile - ${this.formData.name}`;
      }
    }
  },
  watch: {
    file() {
      this.formData = cloneDeep(this.file);
      if (this.formData.mimeType === 'application/x-directory') {
        this.isFolder = true;
      } else {
        this.isFolder = false;
      }
    },
    formData: {
      deep: true,
      handler: function (val) {
        this.showCMetaInputs = true
        if (val.fileName) this.nameValidate = true;
        if (val.id) this.idValidate = true;
      },
    },
  },
  methods: {
    ...mapActions('files', ['handleUpdateFile', 'handleGetFileById']),

    initialize() {
      this.handleGetFileById(this.$route.params.fileId);
      routerBreadcrumbs(this.$router.currentRoute);
    },
    updateFile() {
      this.nameValidate = this.formData.fileName ? true : false;
      this.idValidate = this.formData.id ? true : false;
      this.repoIdValidate = this.formData.repositoryId ? true : false;

      const body = [
        {
          id: this.formData.id,
          created: this.formData.created,
          fileName: this.formData.fileName,
          type: this.formData.type,
          tags: this.formData.tags,
          metadata: this.formData.metadata,
          repositoryId: this.formData.repositoryId,
          repoName: this.$route.params.name
        },
      ];

      if (this.nameValidate && this.idValidate && this.repoIdValidate) {
        this.handleUpdateFile(body);
      }
    },
    dateFormate,
    addTags(newTag) {
      this.options.push(newTag);
      this.formData.tags.push(newTag);
    },
    addMeta(meta) {
      this.formData.metadata = meta;
    },
  },
};
</script>

<style lang="scss">
.edit-file {
  height: 100%;
  .edit-file-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
      .custom-multi-select .multiselect__select {
        opacity: 0;
      }
      .editor {
        min-height: 200px;
        height: 200px;
      }
    }
  }
}
</style>
